import { useContext } from "react";
import styled from "styled-components";
import { ZoneRouteContext } from "../../../store/zoneRouteContext";
import BiggerThanIcon from "../../../assets/icons/Home/BiggerThanIcon";
import { Link, useNavigate } from "react-router-dom";
import { Domains } from "../constant";
import ButtonWithDropDown from "../../../kit/Button/ButtonWithDropDown";
import emergencyIcon from "../shorcutIcons/Mock/MockIcons/emergency.svg";
import { getRouteByValue } from "../utils";

import useMediaQueryConstants from "../../../customHooks/useMediaQueryConstants";
import useCurrentUser from "../../../customHooks/useCurrentUser";
import { useGetUserDomains } from "../../../customHooks/useGetUserDomains";
import MuiButton from "../../../kit/Button/MuiButton";
import { Box } from "@mui/material";
import { FaAngleDown } from "react-icons/fa";

const Wrapper = styled.div`
  margin: auto 11px;
  margin-top: 15px;
  border: 2px solid #4b778d;
  border-radius: 21.9px;
  position: relative;
  min-height: ${({ isWideScreen }) => (isWideScreen ? "92%" : "auto")};
`;
const Row = styled.div`
  margin-top: 10px;
  display: flex;
`;
const LeftSide = styled.div`
  width: ${({ isWideScreen }) => (isWideScreen ? "100%" : "45%")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: start;
  margin-left: 10px;
`;
const StyledImage = styled.img`
  width: 117px;
  height: 115px;
  text-align: center;
  border-radius: 21.9016px;
`;

const MoreWrapper = styled.div`
  margin-top: 5px;
  margin-right: 70px;
`;
const More = styled(Link)`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #4b778d;
  font-family: "Assistant";
`;

const RightSide = styled.div`
  width: 55%;
  margin-top: 30px;
`;

const InfoWrapper = styled.div`
  margin-left: ${({ isWideScreen }) => (isWideScreen ? "0px" : "10px")};
  margin-top: 25px;
`;

const Name = styled.div`
  color: #4b778d;
  font-family: "Assistant";
  font-size: 19px;
  font-weight: 700;
  line-height: 16.426px; /* 86.454% */
`;

const City = styled.div`
  color: #7fb383;
  font-family: "Assistant";
  font-size: 20px;
  font-weight: 600;
  line-height: 23.89px; /* 119.452% */
  margin-top: 5px;
  margin-bottom: 5px;
`;

const ThirdLine = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 23.89px;
  color: #4b778d;
`;

const LinksTitle = styled.h3`
  margin: 10px auto;
  color: #4b778d;
  text-align: center;
  font-family: "Assistant";
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.404px;
`;
const ButtonWrapper = styled.div`
  width: 90%;
  padding: 5px 0 15px;
  margin: auto;
`;
const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50.5px;
  height: 26.5px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#fff"};
  border: ${(props) => `1px solid ${props.color}`};
  border-radius: 13.5px 16px 16px 13.5px;
  cursor: pointer;
`;
const IconImage = styled.img``;
const IconAndLInkWrapper = styled.div`
  direction: rtl;
  display: flex;
  justify-content: space-evenly;
  margin-top: ${({ isWideScreen }) => (isWideScreen ? "15px" : "0")};
`;
const LinkButton = styled(Link)`
  color: #7fb383;
  text-shadow: 0px 0.9374998807907104px 0.9374998807907104px rgba(0, 0, 0, 0.12);
  font-family: Assistant;
  font-size: 13.143px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.394px;
  border-radius: 14.062px;
  border: 1.438px solid #7fb383;
  padding: 5px 16px;
  text-decoration: none;
  box-shadow: 0px 1.875px 4.6875px 0px rgba(66, 132, 128, 0.2);
`;
const IconAndLinkWrapper = styled.div`
  display: ${({ isWideScreen }) => (isWideScreen ? "block " : "flex")};
  justify-content: space-between;
  gap: 15px;
  position: absolute;
  top: 11px;
  right: 15px;
  direction: rtl;
`;
function UserCard({ isAbstractEmergency, hideDropdown }) {
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const navigate = useNavigate();
  const { homeZone, setHomeZone } = useContext(ZoneRouteContext);

  const { currentUser } = useCurrentUser();
  const userDomains = useGetUserDomains();

  return (
    <Wrapper isWideScreen={isWideScreen || isMediumScreen}>
      <Row>
        <LeftSide isWideScreen={isWideScreen || isMediumScreen}>
          <StyledImage src={currentUser.image} alt="" />
          {(isWideScreen || isMediumScreen) && (
            <InfoWrapper isWideScreen={isWideScreen || isMediumScreen}>
              <Name>{currentUser.displayName}</Name>
              <City>Together In Tel Aviv</City>
            </InfoWrapper>
          )}
          <MoreWrapper>
            <More to={"/profile"}>More</More>
            <BiggerThanIcon />
          </MoreWrapper>
        </LeftSide>
        {!(isWideScreen || isMediumScreen) && (
          <RightSide>
            <InfoWrapper isWideScreen={isWideScreen || isMediumScreen}>
              <Name>{currentUser.displayName}</Name>
              <City>
                {isAbstractEmergency
                  ? "Together In Emergency"
                  : "Together In Tel Aviv"}
              </City>
              {isAbstractEmergency && (
                <ThirdLine>Team Member - Paramedic</ThirdLine>
              )}
            </InfoWrapper>
          </RightSide>
        )}
      </Row>
      <LinksTitle>Community - בחירת קהילה</LinksTitle>

      {!hideDropdown && (
        <>
          {isAbstractEmergency ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "5px 0 15px",
              }}
            >
              <MuiButton
                variant="outlined"
                endIcon={<FaAngleDown />}
                sx={{
                  fontFamily: "Assistant",
                  borderRadius: "12px",
                  width: "100%",
                  height: 28,
                  textTransform: "none",
                  backgroundColor: "#CEDCEF",
                  textDecoration: "underline",
                  color: "#337A37",
                  fontSize: 18,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  boxSizing: "border-box",
                  margin: "0 30px",
                  "&:hover": {
                    backgroundColor: "#CEDCEF",
                    textDecoration: "underline",
                  },
                }}
              >
                {"Organization  - בחירת ארגון"}
              </MuiButton>
            </Box>
          ) : (
            <ButtonWrapper>
              <ButtonWithDropDown
                activeLink={homeZone?.name}
                links={userDomains ?? Domains}
                color={"#4B778D"}
                activeLinkColor={"#7FB383"}
                fontSizeActiveLink="21.472px"
                border="none"
                iconColor="#7FB383"
                textAlign="left"
                height="34px"
                borderRadius={"30px"}
                searchFormheight={"30px"}
                searchFormFontSize={"15px"}
                iconHeight={"20px"}
                iconWidth={"20px"}
                searchFormColor={"gray"}
                backgroundColor={"#fff"}
                fontWeightActiveLink={600}
                activeLinkMargin={"0 0 10px 0;"}
                showUnderline={true}
                isRoutes={false}
                setState={(value) =>
                  setHomeZone(getRouteByValue(value, userDomains))
                }
              />
            </ButtonWrapper>
          )}
        </>
      )}
      <IconAndLinkWrapper isWideScreen={isWideScreen || isMediumScreen}>
        <Icon
          color={"#FF9737"}
          backgroundColor={"#FF9737"}
          onClick={() => navigate("/emergency/contacts/panic-status")}
        >
          <IconImage src={emergencyIcon} alt="" />
        </Icon>
        <IconAndLInkWrapper isWideScreen={isWideScreen || isMediumScreen}>
          <LinkButton to={homeZone.route}>דף הבית</LinkButton>
        </IconAndLInkWrapper>
      </IconAndLinkWrapper>
    </Wrapper>
  );
}

export default UserCard;
