import { USER_ROLES } from "../Auth/constant";
import { ROUTES as MAIN_ROUTES, REGION } from "../Common/Layout/Main/constants";
import {
  TogetherAdminIcon,
  TogetherVolunteerEcosystem,
  TogetherTeamMemberIcon,
} from "../../assets/icons/Logo";
import UserCard from "./Cards/UserCard";
import AdminCard from "./Cards/AdminCard";
import helpNeededIconV2 from "../../assets/icons/helpNeededIconV2.png";
import TeamIcon from "../../assets/icons/TeamIcon.png";
import walletIcon from "../../assets/icons/walletIcon.png";
import BellIcon from "../../assets/icons/Activities/LandingPage/BellIcon";
import ambulanceIcon from "../Ecosystem/Zone/Common/EmergencyButtons/Ambulance.png";
import wApple from "../Common/Layout/Navbar/components/Icons/wApple.png";
import { OrangeMapIcon } from "../../assets/icons/Activities/LandingPage/OrangeMapIcon";

export const ROUTES = {
  TEL_AVIV: "tel-aviv",
  RAMAT_HASHARON: "ramat-hasharon",
  NOGA: "noga",
  School: "student",
  TASKS: "tasks",
  COMUNITY: "comunity",
  REWARD: "reward",
  PENDING: "pending",
};
export const APP_MAX_WIDTH = 430;

export const Domains = [
  {
    name: "Together In Tel Aviv",
    route: `/activities/together/${ROUTES.TEL_AVIV}`,
    value: ROUTES.TEL_AVIV,
  },
  {
    name: "רמת השרון",
    route: `/activities/view/659e2467e6ad34f2f223b4f4`,
    value: ROUTES.RAMAT_HASHARON,
  },
  {
    name: "Ort School",
    route: `/activities/${ROUTES.School}/`,
    value: ROUTES.School,
  },
  {
    name: "Noga community",
    route: `/activities/together/${ROUTES.NOGA}`,
    value: ROUTES.NOGA,
  },
];

export const otherDomains = [
  {
    name: "Tasks & Activities",
    hebText: "משימות ופעילויות",
    subText: "22",
    route: "/tasks",
    value: ROUTES.TASKS,
  },
  {
    name: "Community & team member",
    hebText: "קהילה וחברי צוות",
    route: "/messages/comunity-friends",
    value: ROUTES.COMUNITY,
  },
  {
    name: "Volunteer  All  Pending",
    hebText: "בהמתנה / בטיפול",
    subText: "3",
    route: "/reward/pending",
    value: ROUTES.PENDING,
  },
  {
    name: "Volunteer  Reward",
    hebText: "תגמולי מתנדבים",
    route: "/reward",
    value: ROUTES.REWARD,
    image: wApple,
    // icon: <WAppleIcon />,
  },
];

export const volunteerAbstractEmergencyDomains = [
  {
    hebText: "מפות קריאות לעזרה",
    subText: "",
    route: `/activities/map/${REGION.Abstract_Emergency}/telAvivCity`,
    value: `/activities/map/${REGION.Abstract_Emergency}/telAvivCity`,
    icon: <OrangeMapIcon />,
  },
  {
    hebText: "בקשות לסיוע דחוף",
    subText: "",
    route: `/activities/map/${REGION.Abstract_Emergency}/telAvivCity`,
    value: `/activities/map/${REGION.Abstract_Emergency}/telAvivCity`,
    icon: (
      <img width={"31"} height={"31"} src={helpNeededIconV2} alt={"help"} />
    ),
  },
  {
    hebText: "קריאות מצוקה",
    subText: "",
    route: `/activities/map/${REGION.Abstract_Emergency}/telAvivCity`,
    value: `/activities/map/${REGION.Abstract_Emergency}/telAvivCity`,
    icon: <BellIcon width={"28"} height={"30"} color={"#E81616"} />,
  },
  {
    hebText: "ארגוני חירום והצלה",
    subText: "",
    route: `/activities/map/${REGION.Abstract_Emergency}/telAvivCity`,
    value: `/activities/map/${REGION.Abstract_Emergency}/telAvivCity`,
    icon: (
      <img width={"37"} height={"37"} src={ambulanceIcon} alt={"ambulance"} />
    ),
  },
  {
    hebText: "איזור אישי",
    subText: "",
    route: "/reward",
    value: "/reward",
    icon: <img width={"30"} height={"33"} src={walletIcon} alt={"apple"} />,
  },
];

export const AdminTeamMemberAbstractEmergencyDomains = [
  {
    hebText: "חברי הצוות",
    subText: "",
    route: "/admin/requests",
    value: "/admin/requests",
    icon: <img width={"41"} height={"41"} src={TeamIcon} alt={"Team"} />,
  },
  ...volunteerAbstractEmergencyDomains,
];

export const TeamMemberDomains = [
  {
    name: "Team Member Area",
    hebText: "ניהול קהילה",
    route: null,
    value: null,
  },
  {
    name: "Community Board",
    hebText: "לוח קהילתי",
    route: "/activities/board/zone/telAvivCity",
    value: ROUTES.COMUNITY,
  },
  {
    name: "Tasks & Activities",
    hebText: "משימות פעילויות ואירועים",
    subText: "22",
    route: "/tasks",
    value: ROUTES.TASKS,
  },
  {
    name: "Volunteer  Time  Reward",
    hebText: "תגמולי מתנדבים",
    route: "/reward",
    value: ROUTES.REWARD,
  },
  {
    name: "Volunteer  All  Pending",
    hebText: "בהמתנה / בטיפול",
    subText: "3",
    route: "/reward/pending",
    value: ROUTES.PENDING,
  },
];

export const AdminDomains = [
  {
    name: "Admin Area",
    hebText: "ניהול קהילה",
    route: `${MAIN_ROUTES.ADMIN}/requests`,
    value: `${MAIN_ROUTES.ADMIN}/requests`,
  },
  {
    name: "Community Board",
    hebText: "לוח קהילתי",
    route: "/activities/board/zone/telAvivCity",
    value: ROUTES.COMUNITY,
  },
  {
    name: "Tasks & Activities",
    hebText: "משימות פעילויות ואירועים",
    subText: "22",
    route: "/tasks",
    value: ROUTES.TASKS,
  },
  {
    name: "Volunteer  Time  Reward",
    hebText: "תגמולי מתנדבים",
    route: "/reward",
    value: ROUTES.REWARD,
  },
  {
    name: "Volunteer  All  Pending",
    hebText: "בהמתנה / בטיפול",
    subText: "3",
    route: "/reward/pending",
    value: ROUTES.PENDING,
  },
];

export const Cards = {
  User: "user",
  Elephant: "elephant",
};

export const TABS = {
  [USER_ROLES.ADMIN]: AdminDomains,
  [USER_ROLES.TEAM_MEMBER]: AdminDomains,
  [USER_ROLES.MEMBER]: otherDomains,
};

export const ICONS = {
  [USER_ROLES.ADMIN]: TogetherAdminIcon,
  [USER_ROLES.TEAM_MEMBER]: TogetherTeamMemberIcon,
  [USER_ROLES.MEMBER]: TogetherVolunteerEcosystem,
};

export const CARD_COMPONENTS = {
  [USER_ROLES.ADMIN]: <AdminCard />,
  [USER_ROLES.TEAM_MEMBER]: <AdminCard />,
  [USER_ROLES.MEMBER]: <UserCard />,
};

export const ABSTRACT_EMERGENCY_CARD_COMPONENTS = {
  [USER_ROLES.ADMIN]: <AdminCard isAbstractEmergency />,
  [USER_ROLES.TEAM_MEMBER]: <AdminCard hideDropdown isAbstractEmergency />,
  [USER_ROLES.MEMBER]: <UserCard isAbstractEmergency />,
};
