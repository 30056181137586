import React, { useRef, useState } from "react";
import { useEffect } from "react";
import styled, { css } from "styled-components";
const Wrapper = styled.button`
  border-radius: ${(props) => props.borderRadius ?? "106.061px"};
  border: 2.121px solid ${(props) => props.color ?? "#ef7300"};
  display: flex;
  justify-content: space-between;
  gap: 10px;
  background-color: ${(props) => props.backgroundColor ?? "transparent"};
  align-items: center;
  direction: rtl;
  width: ${(props) => props.width ?? "90%"};
  position: relative;
  cursor: pointer;
  overflow: hidden;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  margin-top: 5px;

  ${(props) =>
    props.styles &&
    css`
      ${Object.entries(props.styles).map(([key, value]) => `${key}: ${value};`)}
    `}
`;

const Text = styled.p`
  margin: 0;
  color: ${(props) => props.color ?? "#ef7300CC"};
  font-family: "NotoSans-600";
  font-size: ${(props) => props.fontSize ?? "19.091px"};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;

const SubText = styled.p`
  color: ${(props) => props.color ?? "#ef730011"};
  margin: 0;
  font-family: "NotoSans-600";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;

const ContentWrapper = styled.div`
  padding: ${({ padding }) => padding ?? "10px"};
  display: flex;
  justify-content: space-between;
  gap: 10px;
  background-color: transparent;
  align-items: center;
  direction: rtl;
  width: 100%;
  position: ${({ position }) => position ?? "relative"};
  z-index: 1;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;

const SendButton = ({
  color,
  isReadyToSend,
  onSubmit,
  padding,
  backgroundColor,
  reverseOrder,
  borderRadius,
  width,
  text,
  styles,
}) => {
  const [holding, setHolding] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [countDown, setCountDown] = useState(1);
  const timerRef = useRef(null);
  const countDownRef = useRef(null);

  const handleMouseDown = () => {
    setHolding(true);
    setPercentage(0);

    timerRef.current = setInterval(() => {
      setPercentage((prevPercentage) => {
        const newPercentage = prevPercentage + 100 / 100;
        if (newPercentage >= 100) {
          onSubmit?.();
        }
        return newPercentage >= 100 ? 100 : newPercentage;
      });
    }, 10);

    countDownRef.current = setInterval(() => {
      setCountDown((c) => c - 1);
    }, 1000);
  };

  const handleMouseUp = () => {
    clearInterval(timerRef.current);
    clearInterval(countDownRef.current);

    if (percentage >= 100) {
      onSubmit?.();
    }

    setHolding(false);
    setPercentage(0);
    setCountDown(1);
  };

  useEffect(() => {
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  return (
    <Wrapper
      color={color}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      width={width}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
      onTouchCancel={handleMouseUp}
      onClick={(e) => e.preventDefault()}
      styles={styles?.wrapper}
    >
      <ContentWrapper padding={padding}>
        {!holding ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0 5px",
              gap: "20px",
            }}
          >
            {text ? (
              text
            ) : reverseOrder ? (
              <>
                <Text fontSize={"14px"} color={color}>
                  (לחצו 2 שניות)
                </Text>
                <Text color={color}>Send</Text>
              </>
            ) : (
              <>
                <Text color={color}>Send</Text>
                <Text fontSize={"14px"} color={color}>
                  (לחצו 2 שניות)
                </Text>
              </>
            )}
          </div>
        ) : (
          <>
            <SubText color={color}>לחצו</SubText>
            <SubText color={color}>{countDown}</SubText>
            <Text color={color}>Hold </Text>
          </>
        )}
      </ContentWrapper>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: `${percentage.toFixed(2)}%`,
          height: "100%",
          backgroundColor: "rgba(127, 179, 131, 0.5)",
          zIndex: 0,
        }}
      />
    </Wrapper>
  );
};

export default SendButton;
