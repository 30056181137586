import React, { useContext, useState } from "react";
import styled from "styled-components";
import Police from "./Police.png";
import Army from "./Army.png";
import FireFighters from "./FireFighters.png";
import Support from "./Support.png";
import SupportGroup from "./SupportGroup.png";
import { hospitalBusIconV2 } from "../../../../Auth/landingPage/images";
import MuiButton from "../../../../../kit/Button/MuiButton";
import { FlexColumn, FlexRow } from "../../../../Common/Styled";
import { EmergencyOverlaysContext } from "../../EmergencyOverlaysContext";
import useSendWhatsappMessage from "../../hooks/useSendWhatsappMessage";
import { IoIosCheckmarkCircle } from "react-icons/io";

const Wrapper = styled.div``;
const Title = styled.h2`
  margin: 0;
  color: #337a37;
  text-align: center;
  font-family: Assistant;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  margin-bottom: 10px;
`;
const OverlayCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background: ${({ selected, selectedColor }) =>
    selected ? selectedColor ?? "#7FB383" : "f3f8f1"};
  box-shadow: 2px 2px 5px 1px rgba(70, 57, 77, 0.5);
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  padding: 0 25px;
  height: 60px;
`;
const Name = styled.h4`
  margin: 0;
  color: ${({ selected }) => (selected ? "#FFF" : "#337a37")};
  text-align: right;
  font-family: Assistant;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
  direction: rtl;
  margin-left: auto;
`;
const Phone = styled.p`
  margin: 0;
  width: 50%;
  color: #337a37;
  text-align: center;
  font-family: Assistant;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
`;

const Contacts = [
  {
    name: "משטרה",
    phone: "052-2020100",
    clickDescription: "לחצו לבחירת איש קשר",
    img: Police,
  },
  {
    name: "צבא וביטחון",
    phone: " 052-9104104",
    clickDescription: "צבא וביטחון",
    img: Army,
  },
  {
    name: "כיבוי והצלה",
    phone: " 050-5960735",
    clickDescription: "לחצו לבחירת איש קשר",
    img: FireFighters,
  },
  {
    name: "רפואה",
    phone: " 050-5960735",
    clickDescription: "לחצו לבחירת איש קשר",
    img: hospitalBusIconV2,
  },
  {
    name: "מוקדים חירום עירוניים",
    clickDescription: "לחצו לבחירת איש קשר",
    img: Support,
    imgWidth: "44px",
    imgHeight: "44px",
  },
  {
    name: "אנשי קשר לחירום",
    clickDescription: "לחצו לבחירת איש קשר",
    img: SupportGroup,
    imgWidth: "51px",
    imgHeight: "51px",
  },
];
const EmergencyContact = ({ onClose }) => {
  const { theme } = useContext(EmergencyOverlaysContext);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { seneWhatsappMessage, isLoading: isSendingMessage } =
    useSendWhatsappMessage();
  const onClick = (index) => {
    if (selectedOptions.includes(index)) {
      setSelectedOptions(selectedOptions.filter((n) => n !== index));
      return;
    }
    setSelectedOptions(selectedOptions.concat([index]));
  };
  const handleButtonClick = (index) => {
    setSelectedOptions((prev) =>
      prev.includes(index) ? prev.filter((n) => n !== index) : [...prev, index]
    );
  };
  const handleSendClick = async () => {
    const selectedContacts = selectedOptions.map(
      (index) => Contacts[index].phone
    );
    try {
      await seneWhatsappMessage(selectedContacts);
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        onClose();
      }, 2000);
    } catch (e) {
      console.error(e);
    }
  };

  if (showSuccessMessage) {
    return (
      <FlexColumn
        sx={{
          color: "#337A37",
          fontFamily: "Assistant",
          fontSize: 25,
          fontWeight: 700,
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
          padding: "15px 0",
        }}
      >
        <div>הודעתך למוקדי החירום </div>
        <div>נשלחה בהצלחה</div>
        <FlexRow alignItems={"center"} gap={"10px"}>
          <div>Confirmed</div>
          <IoIosCheckmarkCircle color={"#4BAE4F"} fontSize={40} />
        </FlexRow>
      </FlexColumn>
    );
  }

  return (
    <Wrapper>
      <Title>הודעה ישירה למוקדי החירום</Title>
      <FlexColumn sx={{ gap: "15px" }}>
        {Contacts.map((ec, index) => (
          <OverlayCard
            selected={selectedOptions.includes(index)}
            selectedColor={theme?.tertiary ?? theme?.primary}
            onClick={() => onClick(index)}
          >
            {ec.img && (
              <img
                style={{
                  width: ec?.imgWidth ?? "58px",
                  height: ec?.imgHeight ?? "58px",
                }}
                src={ec.img}
                alt={"icon"}
              />
            )}
            <Name selected={selectedOptions.includes(index)}>{ec.name}</Name>
          </OverlayCard>
        ))}
      </FlexColumn>
      {selectedOptions.length > 0 && (
        <MuiButton
          onClick={handleSendClick}
          sx={{
            color: theme?.primary ?? "#EF7300",
            fontSize: 20,
            fontWeight: 600,
            fontFamily: "NotoSans-600",
            border: `2.12px solid ${theme?.primary ?? "#EF7300"}`,
            borderRadius: "33px",
            width: 203,
            height: 36,
            margin: "20px auto 0",
            textTransform: "none",
            display: "flex",
            justifyContent: "space-around",
            "&:hover": {
              border: `2.12px solid ${theme?.primary ?? "#EF7300"}`,
            },
          }}
        >
          {isSendingMessage ? (
            "Sending..."
          ) : (
            <>
              <div>Send</div>
              <div>שלח</div>
            </>
          )}
        </MuiButton>
      )}
    </Wrapper>
  );
};

export default EmergencyContact;
