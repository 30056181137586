import { useMutation } from "@tanstack/react-query";
import { sendWhatsappMessage } from "../api";

function useSendWhatsappMessage() {
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (payload) => sendWhatsappMessage(payload),
  });
  return { seneWhatsappMessage: mutateAsync, isLoading };
}

export default useSendWhatsappMessage;
