import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import mapImage from "./mapImage.png";
import { RedCircle } from "../../assets/icons";
import { EmergencyOverlaysContext } from "../../domain/Emergency/Contacts/EmergencyOverlaysContext";

const Wrapper = styled.div`
  position: absolute;
  border-radius: 7.449px;
  border: 2.273px solid #000;
  background: #fafffa;
  box-shadow: 0px 11.094px 11.094px 0px rgba(0, 0, 0, 0.25);
  width: 133.706px;
  height: 122.16px;
  top: 15px;
  right: 10px;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const Title = styled.div`
  color: #003f5d;
  font-family: Assistant;
  font-size: 8.194px;
  font-weight: 700;
  letter-spacing: 0.164px;
  text-align: center;
`;

const Image = styled.img`
  width: 106.89px;
  height: 76.066px;
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: -10px;
  left: -10px;
  cursor: pointer;
`;

const flash = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const FlashingRedCircle = styled.div`
  animation: ${flash} 1s infinite;
`;

const MapPopup = ({ onClose = () => {}, id }) => {
  const { setShowOverlay, setOverlayProps, theme } = useContext(
    EmergencyOverlaysContext
  );

  return (
    <Wrapper
      onClick={() => {
        setShowOverlay(true);
        setOverlayProps({ id, isNeedHelp: true, forceShowConfirm: true });
        onClose?.(id);
      }}
    >
      <Title>
        <div>בקשתך לסיוע התקבלה,</div>
        <div>העזרה בדרך אליך!</div>
      </Title>
      <Image src={mapImage} alt="mapImage" />
      <CloseWrapper>
        <FlashingRedCircle>
          <RedCircle color={theme?.primary} />
        </FlashingRedCircle>
      </CloseWrapper>
    </Wrapper>
  );
};

export default MapPopup;
