import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import useCurrentRoute from "../../customHooks/useCurrentRoute";

const Container = styled.div`
  display: flex;
  gap: 5px;
`;

const CodeInput = ({
  setValue,
  required,
  initialValue,
  disabled,
  navigateLink,
  styles,
  inputNum = 4,
}) => {
  const navigate = useNavigate();

  const initVal =
    initialValue && initialValue !== ""
      ? initialValue?.split?.("")
      : Array(inputNum).fill("");
  const [code, setCode] = useState(initVal);
  const inputRefs = useRef([]);
  const currentRoute = useCurrentRoute();
  const updateValue = (newCode) => {
    setValue?.(newCode.join(""));
  };
  const isContainsANumber = (currentValue) => currentValue !== "";
  const navigateToTargetLink = () => {
    return code.every(isContainsANumber);
  };
  const handleInputChange = (e, index) => {
    const { value } = e.target;
    if (!/\d/.test(value) || index >= 10) {
      return;
    }

    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
    updateValue(newCode);
    if (index < 3) {
      inputRefs.current[index + 1].focus();
      //TODO: Check the code*
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key !== "Backspace") {
      return;
    }

    if (index > 0) {
      const newCode = [...code];
      newCode[index] = "";
      setCode(newCode);
      updateValue(newCode);
      inputRefs.current[index - 1].focus();
      return;
    }

    if (index === 0) {
      const newCode = [""].concat(code.slice(1));
      setCode(newCode);
      updateValue(newCode);
      inputRefs.current[index].focus();
    }
  };
  useEffect(() => {
    if (navigateToTargetLink() && navigateLink)
      navigate(`${currentRoute}/${navigateLink}`);
  }, [code]);
  return (
    <Container>
      {code?.map((digit, index) => (
        <div key={index}>
          <input
            ref={(ref) => (inputRefs.current[index] = ref)}
            required={required}
            disabled={disabled}
            type="text"
            pattern="\d*"
            value={digit}
            maxLength={1}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            className="digit-input"
            style={{
              backgroundColor: "#FFF6F6",
              border: "1px solid #003F5D",
              outline: "none",
              width: "28px",
              height: "29px",
              textAlign: "center",
              borderRadius: "5px",
              padding: 0,
              color: "black",
              ...styles,
            }}
          />
        </div>
      ))}
    </Container>
  );
};

export default CodeInput;
