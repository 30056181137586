import React, { useContext, useState } from "react";
import styled, { css } from "styled-components";
import MuiButton from "../../../../../kit/Button/MuiButton";
import Maintinance from "../../../../../assets/icons/Maintinance.png";
import { FlexColumn, FlexRow } from "../../../../Common/Styled";
import { EmergencyOverlaysContext } from "../../EmergencyOverlaysContext";
import { needHelpIconV2 } from "../../../../Auth/landingPage/images";
import {
  firefighterImage,
  medicineImage,
  mentalImage,
  logisticalImage,
  contactsImage,
} from "./v2Icons";
import useSendWhatsappMessage from "../../hooks/useSendWhatsappMessage";
import { IoIosCheckmarkCircle } from "react-icons/io";

const Wrapper = styled.div`
  width: calc(100% - 16px);
`;

const Title = styled.h2`
  margin: 0;
  color: #337a37;
  text-align: center;
  font-family: Assistant;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  margin-bottom: 10px;
  div {
    line-height: 27px;
  }
  ${(props) =>
    props.styles &&
    css`
      ${props.styles}
    `}
`;

const OverlayCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background: ${({ selected, selectedColor }) =>
    selected ? selectedColor ?? "#F3F8F1" : "f3f8f1"};
  box-shadow: 2px 2px 5px 1px rgba(70, 57, 77, 0.5);
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  padding: 0 25px;
  height: 60px;
`;

const Name = styled.h4`
  margin: 0;
  color: ${({ selected }) => (selected ? "#FFF" : "#337a37")};
  text-align: right;
  font-family: Assistant;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
  direction: rtl;
  margin-left: auto;
`;

const SuccessMessage = styled.div`
  background-color: #e5f7e8;
  border: 1px solid #337a37;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  margin-top: 20px;

  p {
    font-family: Assistant;
    font-size: 18px;
    color: #337a37;
    margin: 0;
  }
`;

const buttons = [
  {
    title: "כבאות והצלה",
    image: firefighterImage,
    phone: "972523153355",
  },
  {
    title: "רפואה",
    image: medicineImage,
    phone: "972523153355",
  },
  {
    title: "עזרה נפשית",
    image: mentalImage,
    phone: "972523153355",
  },
  {
    title: "סיוע לוגיסטי דחוף",
    image: logisticalImage,
    phone: "972523153355",
  },
  {
    title: "ידידים בכבישים",
    image: Maintinance,
    phone: "972523153355",
  },
  {
    title: "אנשי קשר לחירום",
    image: contactsImage,
    phone: "972523153355",
  },
];

const EmergencyContactV2 = ({ onClose, styles }) => {
  const [clickedButtons, setClickedButtons] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const { theme } = useContext(EmergencyOverlaysContext);
  const { seneWhatsappMessage, isLoading: isSendingMessage } =
    useSendWhatsappMessage();

  const handleButtonClick = (index) => {
    setClickedButtons((prev) =>
      prev.includes(index) ? prev.filter((n) => n !== index) : [...prev, index]
    );
  };

  const handleSendClick = async () => {
    const selectedContacts = clickedButtons.map(
      (index) => buttons[index].phone
    );
    try {
      await seneWhatsappMessage(selectedContacts);
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        onClose();
      }, 2000);
    } catch (e) {
      console.error(e);
    }
  };

  if (showSuccessMessage) {
    return (
      <FlexColumn
        sx={{
          color: "#337A37",
          fontFamily: "Assistant",
          fontSize: 25,
          fontWeight: 700,
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
          padding: "15px 0",
        }}
      >
        <div>הודעתך למוקדי החירום </div>
        <div>נשלחה בהצלחה</div>
        <FlexRow alignItems={"center"} gap={"10px"}>
          <div>Confirmed</div>
          <IoIosCheckmarkCircle color={"#4BAE4F"} fontSize={40} />
        </FlexRow>
      </FlexColumn>
    );
  }

  return (
    <Wrapper>
      <FlexRow
        sx={{ justifyContent: "center", alignContent: "center", gap: "20px" }}
      >
        <img
          style={{ height: 40, width: 34 }}
          src={needHelpIconV2}
          alt="headerImage"
        />
        <Title styles={styles?.title}>
          <div>בקשת סיוע</div>
          <div>ממתנדבים והקהילה</div>
        </Title>
      </FlexRow>
      <FlexColumn sx={{ gap: "15px" }}>
        {buttons.map((button, index) => {
          const isClicked = clickedButtons.includes(index);
          return (
            <OverlayCard
              key={button.title}
              selected={isClicked}
              selectedColor="#83C9E4"
              onClick={() => handleButtonClick(index)}
            >
              <img
                style={{ width: "40px", height: "40px" }}
                src={button.image}
                alt={"icon"}
              />
              <Name selected={isClicked}>{button.title}</Name>
            </OverlayCard>
          );
        })}
      </FlexColumn>
      {clickedButtons.length > 0 && (
        <>
          <MuiButton
            onClick={handleSendClick}
            disabled={isSendingMessage}
            sx={{
              color: theme?.primary ?? "#EF7300",
              fontSize: 20,
              fontWeight: 600,
              fontFamily: "NotoSans-600",
              border: `2.12px solid ${theme?.primary ?? "#EF7300"}`,
              borderRadius: "33px",
              width: 203,
              height: 36,
              margin: "20px auto 10px",
              textTransform: "none",
              display: "flex",
              justifyContent: "space-around",
              "&:hover": {
                border: `2.12px solid ${theme?.primary ?? "#EF7300"}`,
              },
              ...styles?.button,
            }}
          >
            {isSendingMessage ? (
              "Sending..."
            ) : (
              <>
                <div>Send</div>
                <div>שלח</div>
              </>
            )}
          </MuiButton>
          <MuiButton
            onClick={handleSendClick}
            sx={{
              color: theme?.primary ?? "#EF7300",
              fontSize: 20,
              fontWeight: 600,
              fontFamily: "NotoSans-600",
              border: `2.12px solid ${theme?.primary ?? "#EF7300"}`,
              borderRadius: "33px",
              width: 203,
              height: 36,
              margin: "10px auto 20px",
              textTransform: "none",
              display: "flex",
              justifyContent: "space-around",
              "&:hover": {
                border: `2.12px solid ${theme?.primary ?? "#EF7300"}`,
              },
              ...styles?.button,
            }}
          >
            <div>Call</div>
            <div>שיחה</div>
          </MuiButton>
        </>
      )}
    </Wrapper>
  );
};

export default EmergencyContactV2;
