import { useContext } from "react";
import styled from "styled-components";
import { UserContext } from "../../../store/userContext";
import { ZoneRouteContext } from "../../../store/zoneRouteContext";
import { Link } from "react-router-dom";
import { Domains } from "../constant";
import ButtonWithDropDown from "../../../kit/Button/ButtonWithDropDown";
import Icon from "../shorcutIcons/Icon";
import emergencyIcon from "../shorcutIcons/Mock/MockIcons/emergency.svg";
import GreenDoubleArrowIcon2 from "../../../assets/icons/GreenDoubleArrowIcon2";
import { getRouteByValue } from "../utils";
import useMediaQueryConstants from "../../../customHooks/useMediaQueryConstants";
import { useGetUserDomains } from "../../../customHooks/useGetUserDomains";
import { Box } from "@mui/material";

const Wrapper = styled.div`
  margin: auto 11px;
  margin-top: 15px;
  border: 2px solid #4b778d;
  border-radius: 21.9px;
  position: relative;
  min-height: ${({ isWideScreen }) => (isWideScreen ? "92%" : "auto")};
`;
const Row = styled.div`
  margin-top: 10px;
  display: flex;
`;
const LeftSide = styled.div`
  width: ${({ isWideScreen }) => (isWideScreen ? "100%" : "45%")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: start;
  margin-left: 10px;
`;
const StyledImage = styled.img`
  width: 117px;
  height: 115px;
  text-align: center;
  border-radius: 21.9016px;
`;

const MoreWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
  margin-bottom: 5px;
`;
const More = styled(Link)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #4b778d;
`;

const RightSide = styled.div`
  width: 55%;
  margin-top: 30px;
`;

const InfoWrapper = styled.div`
  margin-left: ${({ isWideScreen }) => (isWideScreen ? "0px" : "10px")};
  margin-top: 25px;
`;

const Name = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 17.5px;
  color: #4b778d;
  margin-top: 15px;
`;

const City = styled.div`
  color: #7fb383;
  font-family: Assistant;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 23.89px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const ThirdLine = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 23.89px;
  color: #4b778d;
`;

const LinksTitle = styled.h3`
  margin: 10px auto;
  color: #4b778d;
  text-align: center;
  font-family: Assistant;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.404px;
`;
const ButtonWrapper = styled.div`
  width: 90%;
  padding: 5px 0;
  margin: 10px auto;
  border-radius: 15px;
  background: #fff;
  box-shadow: 2px 2px 5px 1px rgba(70, 57, 77, 0.1);
`;
const LinkButton = styled(Link)`
  color: #7fb383;
  text-shadow: 0px 0.9374998807907104px 0.9374998807907104px rgba(0, 0, 0, 0.12);
  font-family: Assistant;
  font-size: 13.143px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.394px;
  border-radius: 14.062px;
  border: 1.438px solid #7fb383;
  padding: 5px 16px;
  text-decoration: none;
  box-shadow: 0px 1.875px 4.6875px 0px rgba(66, 132, 128, 0.2);
`;
const IconAndLinkWrapper = styled.div`
  display: ${({ isWideScreen }) => (isWideScreen ? "block " : "flex")};
  justify-content: space-between;
  gap: 15px;
  position: absolute;
  top: 11px;
  right: 15px;
  direction: rtl;
`;
const IconAndLInkWrapper = styled.div`
  direction: rtl;
  display: flex;
  justify-content: space-evenly;
  margin-top: ${({ isWideScreen }) => (isWideScreen ? "15px" : "0")};
`;
function UserCard({ isAbstractEmergency }) {
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const { currentUser } = useContext(UserContext);
  const { homeZone, setHomeZone } = useContext(ZoneRouteContext);

  const userDomains = useGetUserDomains();

  return (
    <Wrapper isWideScreen={isWideScreen || isMediumScreen}>
      <Row>
        <LeftSide isWideScreen={isWideScreen || isMediumScreen}>
          <StyledImage src={currentUser.image} alt="" />
          {(isWideScreen || isMediumScreen) && (
            <InfoWrapper isWideScreen={isWideScreen || isMediumScreen}>
              <Name>{currentUser.displayName}</Name>
              <City>Together In Tel Aviv</City>
            </InfoWrapper>
          )}
          <MoreWrapper>
            <More to={"/profile"}>Member</More>
            <GreenDoubleArrowIcon2 height={"12"} color={"#6e8d9a"} />
          </MoreWrapper>
        </LeftSide>
        {!(isWideScreen || isMediumScreen) && (
          <RightSide>
            <InfoWrapper isWideScreen={isWideScreen || isMediumScreen}>
              <Name>{currentUser.displayName}</Name>
              <City>
                {isAbstractEmergency
                  ? "Together In Emergency"
                  : "Together In Tel Aviv"}
              </City>
              {isAbstractEmergency && (
                <ThirdLine>Team Member - Paramedic</ThirdLine>
              )}
            </InfoWrapper>
          </RightSide>
        )}
      </Row>
      {!isAbstractEmergency && (
        <>
          <LinksTitle>Community - בחירת קהילה</LinksTitle>
          <ButtonWrapper>
            <ButtonWithDropDown
              activeLink={homeZone.name}
              links={userDomains ?? Domains}
              color={isAbstractEmergency ? "#337A37" : "#4B778D"}
              activeLinkColor={isAbstractEmergency ? "#337A37" : "#7FB383"}
              fontSizeActiveLink="21.472px"
              border="none"
              iconColor="#7FB383"
              textAlign="left"
              height="34px"
              borderRadius={"30px"}
              searchFormheight={"30px"}
              searchFormFontSize={"15px"}
              backgroundColor={isAbstractEmergency ? "#CEDCEF" : "#fff"}
              iconHeight={"20px"}
              iconWidth={"20px"}
              searchFormColor={"gray"}
              fontWeightActiveLink={600}
              activeLinkMargin={"0 0 10px 0;"}
              showUnderline={true}
              isRoutes={false}
              setState={(value) =>
                setHomeZone(getRouteByValue(value, userDomains))
              }
            />
          </ButtonWrapper>
        </>
      )}
      <IconAndLinkWrapper isWideScreen={isWideScreen || isMediumScreen}>
        <Icon
          image={emergencyIcon}
          color="#FF9737"
          backgroundColor="#FF9737"
          destination="/emergency"
          link={"/emergency/private"}
        />
        <IconAndLInkWrapper isWideScreen={isWideScreen || isMediumScreen}>
          <LinkButton to={homeZone.route}>דך הבית</LinkButton>
        </IconAndLInkWrapper>
      </IconAndLinkWrapper>
    </Wrapper>
  );
}

export default UserCard;
