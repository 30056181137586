import React, { useEffect, useMemo, useState } from "react";
import {
  GoogleMap,
  MarkerF,
  StandaloneSearchBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import styled from "styled-components";
import { shouldBlockMapRequest, getCityCoordinates } from "./MapUtils";
import { CircleSVGs, MarkerIcon } from "./CircleConstants";

const LocationInput = styled.input`
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 240px;
  height: 45px;
  padding: 0 12px;
  border-radius: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  border: 1px solid #ef7300;
  text-overflow: ellipses;
  right: 10px;
  top: 60px;
  margin-bottom: 10px;
  width: 100%;
`;

export { MarkerIcon } from "./CircleConstants";

const TelAvivCenter = {
  lat: 32.0853,
  lng: 34.7818,
};

const libraries = ["places"];

const Map = ({
  onLocationSelect,
  height,
  disableClick,
  initialSelectedLocation,
  selectedLocationName,
  setSelectedLocationName,
  expandOnFocus,
  expandedHeight,
  showSearch,
  events,
  borderRadius,
  centerCity,
  showMarkerForCenterCity,
  markers,
}) => {
  const initialSelected = useMemo(() => {
    if (
      initialSelectedLocation == null ||
      isNaN(initialSelectedLocation?.lng) ||
      isNaN(initialSelectedLocation?.lat)
    )
      return null;
    // Ignore default value set in DB
    if (
      initialSelectedLocation?.lng === 31.2323 &&
      initialSelectedLocation?.lat === 32.51651
    ) {
      return TelAvivCenter;
    }
    return initialSelectedLocation;
  }, [initialSelectedLocation]);

  const [selectedLocation, setSelectedLocation] = useState(initialSelected);
  const [expanded, setExpanded] = useState(false);
  const [searchBox, setSearchBox] = useState(null);
  const [searchLocation, setSearchLocation] = useState(null);
  const [centerCityCoords, setCenterCityCoords] = useState();

  useEffect(() => {
    if (!centerCity) return;
    getCityCoordinates(centerCity)
      ?.then((coords) => {
        setCenterCityCoords(coords);
      })
      .catch((er) => console.error("Failed to get city coordinates", er));
  }, [centerCity]);

  const handleMapClick = (event) => {
    if (disableClick) {
      return;
    }
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();

    setSelectedLocation({ lat, lng });
    onLocationSelect?.({ lat, lng });
  };

  const handlePlacesChanged = () => {
    const places = searchBox?.getPlaces?.();

    if (places?.length > 0) {
      setSelectedLocationName?.(places?.[0]?.formatted_address);
      const { lat, lng } = places?.[0]?.geometry?.location;
      const loc = { lat: lat(), lng: lng() };
      setSelectedLocation(loc);
      setSearchLocation(loc);
      onLocationSelect?.(loc);
    }
  };

  const onLoad = (searchBoxInstance) => {
    setSearchBox(searchBoxInstance);
  };

  const { isLoaded } = useJsApiLoader?.({
    id: "google-map-script",
    googleMapsApiKey: shouldBlockMapRequest()
      ? undefined
      : process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    language: "he",
    libraries,
  });

  const updateMapHeight = (expand) => {
    if (!expandOnFocus) {
      return;
    }
    if (expand) {
      setExpanded(true);
      return;
    }
    setExpanded(false);
  };

  const getIcon = (event) => {
    if (event?.markerIcon) {
      return CircleSVGs[event?.markerIcon] ?? CircleSVGs[MarkerIcon.Orange];
    }
    return CircleSVGs[MarkerIcon.Orange];
  };

  const defaultMapOptions = {
    styles: [
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
    ],
  };

  const map = useMemo(
    () => (
      <GoogleMap
        mapContainerStyle={{
          ...(expandOnFocus && { height: expanded ? expandedHeight : height }),
          ...(!expandOnFocus && { height: height ?? "500px" }),
          width: "100%",
          transition: "0.2s",
        }}
        center={
          centerCityCoords || searchLocation || initialSelected || TelAvivCenter
        }
        zoom={13}
        onClick={handleMapClick}
        options={defaultMapOptions}
      >
        {selectedLocation && (
          <MarkerF
            position={selectedLocation}
            icon={getIcon(selectedLocation)}
          />
        )}
        {showMarkerForCenterCity && centerCityCoords && (
          <MarkerF
            position={centerCityCoords}
            icon={getIcon(selectedLocation)}
          />
        )}
        {events?.map((event) => (
          <MarkerF
            key={event._id}
            icon={getIcon(event)}
            onClick={event.onClick}
            position={
              Number(event.longitude) === 31.2323 &&
              Number(event.lattiude) === 32.51651
                ? TelAvivCenter
                : {
                    lat: Number(event.lattiude),
                    lng: Number(event.longitude),
                  }
            }
          />
        ))}
        {markers?.map((marker, index) => (
          <MarkerF
            key={marker._id ?? index}
            position={marker}
            icon={getIcon(marker)}
          />
        ))}
      </GoogleMap>
    ),
    [
      events,
      expandOnFocus,
      expanded,
      expandedHeight,
      handleMapClick,
      height,
      initialSelected,
      searchLocation,
      selectedLocation,
    ]
  );

  useEffect(() => {
    if (initialSelected) {
      setSelectedLocation(initialSelected);
    }
  }, [initialSelected]);

  return isLoaded ? (
    <div
      onFocus={() => (!expanded ? updateMapHeight(true) : undefined)}
      onBlur={() => (expanded ? updateMapHeight(false) : undefined)}
    >
      {showSearch && (
        <StandaloneSearchBox
          onLoad={onLoad}
          onPlacesChanged={handlePlacesChanged}
        >
          <LocationInput
            placeholder="הזנת כתובת"
            value={selectedLocationName ?? ""}
          />
        </StandaloneSearchBox>
      )}
      <div style={{ borderRadius: borderRadius, overflow: "hidden" }}>
        {shouldBlockMapRequest() ? (
          <div
            style={{
              textAlign: "center",
              color: "tomato",
              background: "rgba(0,0,0,0.05)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "25px 0",
            }}
          >
            <span>
              Map request blocked on dev env to save quota.
              <br />
              To enable it, modify:
            </span>
            <span>
              <code style={{ color: "darkgreen" }}>shouldBlockMapRequest</code>{" "}
              function in
              <code style={{ color: "darkgreen" }}> MapUtils.js</code>
            </span>
          </div>
        ) : (
          map
        )}
      </div>
    </div>
  ) : (
    <div>Loading</div>
  );
};

export default Map;
