import React from "react";

function HomeFill({ color = "#007897" }) {
  return (
    <svg
      width="28"
      height="25"
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1101_66573)">
        <path
          d="M20.7725 26.0634H16.1035C15.6102 25.8834 15.4948 25.6195 15.4978 25.1252C15.509 23.1762 15.4938 21.2271 15.5065 19.2785C15.5121 18.438 14.85 17.8781 14.1081 17.8873C13.381 17.8959 12.6533 17.8847 11.9262 17.8924C11.7441 17.8944 11.5499 17.8979 11.3836 17.9605C10.9066 18.14 10.4912 18.5 10.5013 19.2689C10.5273 21.192 10.5034 23.1156 10.5146 25.0393C10.5176 25.5178 10.3727 25.8758 9.91149 26.0629H5.14076C4.45582 25.8803 3.83952 25.588 3.39509 25.0042C3.07067 24.5776 2.81693 24.1138 2.81439 23.5697C2.80116 20.9444 2.80371 18.3185 2.80116 15.6926C2.80116 15.6117 2.80116 15.5309 2.80116 15.4567C2.69031 15.4683 2.60895 15.4851 2.52708 15.4846C1.78061 15.4826 1.15719 15.2055 0.636489 14.6659C-0.210668 13.7888 -0.220329 12.2831 0.648693 11.4131C4.23208 7.82868 7.81241 4.24072 11.3938 0.654281C11.4527 0.595295 11.5168 0.542412 11.5789 0.486477C11.5982 0.473764 11.6175 0.460543 11.6368 0.447831C11.9414 0.307994 12.246 0.168157 12.5511 0.0283203C12.6528 0.0283203 12.754 0.0283203 12.8557 0.0283203H13.1094C13.2111 0.0283203 13.3123 0.0283203 13.414 0.0283203C13.4506 0.0567962 13.4837 0.104086 13.5239 0.111205C14.1061 0.21799 14.5398 0.575972 14.937 0.974635C16.8443 2.88964 18.74 4.81583 20.6449 6.73337C22.1765 8.27514 23.7126 9.81283 25.2519 11.347C25.5824 11.6765 25.8214 12.0467 25.9022 12.513C25.9104 12.5592 25.9663 12.5974 25.9999 12.6391V13.5035C25.9658 13.5447 25.9139 13.5808 25.9012 13.6281C25.6434 14.5505 25.0454 15.1363 24.1306 15.3778C23.8398 15.4546 23.5245 15.4373 23.2123 15.4633C23.2123 16.0389 23.2158 16.614 23.2118 17.1886C23.1955 19.3273 23.1975 21.4661 23.1477 23.6043C23.1284 24.4382 22.6651 25.0764 21.9919 25.5544C21.6247 25.8153 21.2088 25.9643 20.7735 26.0634H20.7725Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1101_66573">
          <rect
            width="26"
            height="26.0351"
            fill="white"
            transform="translate(0 0.0283203)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default HomeFill;
