import { ROUTES as ECOSYSTEM_ROUTES } from "../../../Ecosystem/constant";
import { ROUTES as CITY_ROUTES } from "../../../Ecosystem/Zone/City/constant";
import { ROUTES as ORGANIZATION_ROUTES } from "../../../Ecosystem/Zone/Organization/constant";
import { ROUTES as LOGIN_ROUTES } from "../../../Auth/login/constant";
import { ROUTES as PROFILE_ROUTES } from "../../../Profile/constant";
import { ROUTES as AUTH_ROUTES } from "../../../Auth/constant";
import { ROUTES as REGISTER_ROUTES } from "../../../Auth/Register/constants";
import { ROUTES as LANDING_ROUTES } from "../../../Auth/landingPage/constants";

export const ROUTES = {
  ROOT: "/",
  PRIVATE_ZONE: "activities",
  PUBLIC_ZONE: "public/activities",
  PROFILE: "profile",
  REWARD: "reward",
  MESSAGES: "messages",
  SEARCH: "search",
  ACTIVITIES_AND_TASKS: "tasks",
  NOTIFICATION: "notifications",
  EMERGENCY: "emergency",
  SETTINGS: "settings",
  LOGIN: "login",
  ADMIN: "admin",
  INFO: "info",
};

export const REGION = {
  lOCAL: "local",
  WORLD_WIDE: "worldwide",
  GDEROT_SETTLEMENTS: "gderot_settlements",
  DOD: "dod",
  GderotCommunityCenter: "gderot_community_center",
  Fences48: "fences48",
  BestFriends: "best_friends",
  Emeregncy: "emergency",
  TelAviv: "tel_aviv",
  Abstract_TelAviv: "abstract_tel_aviv",
  RamatHasharon: "ramat_hasharon",
  taglit: "taglit",
  sos: "sos",
  jewish: "jewish",
  israel: "israel",
  vet: "vet",
  education: "education",
  student: "student",
  gderot: "gderot",
  Abstract_Gderot: "abstract_gderot",
  Abstract_Emergency: "abstract_emergency",
  VolunteersAndSpendTime: "VolunteersAndSpendTime",
  JewishVAST: "JewishVAST",
  JewishEmergency: "JewishEmergency",
  gderotV2: "gderotV2",
  matnasim: "matnasim",
  hatzofim: "hatzofim",
  sosV2: "sosv2",
};

const MAIN_ROUTES = ROUTES;
export {
  MAIN_ROUTES,
  ECOSYSTEM_ROUTES,
  CITY_ROUTES,
  ORGANIZATION_ROUTES,
  LOGIN_ROUTES,
  PROFILE_ROUTES,
  AUTH_ROUTES,
  REGISTER_ROUTES,
  LANDING_ROUTES,
};
