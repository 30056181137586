import React, { useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { FiMenu } from "react-icons/fi"; // Assuming you're using react-icons for the menu icon
import { FlexRow } from "../../../../Common/Styled";
import { NAVBAR_TABS } from "../constants";
import {
  isIos,
  isSafari,
} from "../../../../Common/Layout/Header/components/NavBar/utils";
import { UserContext } from "../../../../../store/userContext";
import { useLocation } from "react-router-dom";
import PWAPrompt from "react-ios-pwa-prompt";

const MenuButton = styled.button`
  position: absolute;
  top: 70px;
  right: ${({ isOpen }) => (isOpen ? "0" : "5px")};
  background: #57925c;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;
  width: ${({ isOpen }) => (isOpen ? "203px" : "86px")};
  height: ${({ isOpen }) => (isOpen ? "382px" : "34px")};
  border-radius: ${({ isOpen }) => (!isOpen ? "0 0 0 30px" : "30px 0 0 30px")};
  padding: 0;
  padding-top: ${({ isOpen }) => isOpen && "5px"};
  padding-right: 2px;
`;

const NavTitle = styled.div`
  font-size: ${({ isOpen }) => (isOpen ? "20px" : "16px")};
  font-weight: 700;
`;

const SideContent = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  align-items: center;
  padding: 16px 0;
  margin: 0 8px;
  color: #fff;
  font-size: 17.26px;
  font-weight: 700;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid #fff;
  }
`;

const LandingSideBar = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [iosPWAVisible, setIosPWAVisible] = useState(false);
  const { installPrompt } = useContext(UserContext);
  const [iosPWAKeyOffset, setIosPWAKeyOffset] = useState(0);

  const location = useLocation();
  const currentLocation = useMemo(
    () => `${window.location.origin}${location.pathname}`,
    [location?.pathname]
  );

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleTabClick = (tab) => {
    if (tab.action) {
      if (tab.id === "download_pwa") {
        if (isIos() && isSafari()) {
          setIosPWAVisible(true);
          return;
        }
        installPrompt && tab.action(installPrompt);
      } else if (tab.id === "share") {
        tab.action(currentLocation);
      } else {
        tab.action();
      }
    }
  };

  return (
    <>
      {iosPWAVisible && (
        <PWAPrompt
          debug
          key={iosPWAKeyOffset}
          onClose={() => {
            setIosPWAVisible(false);
            setIosPWAKeyOffset((o) => o + 1);
          }}
          delay={200}
          permanentlyHideOnDismiss={false}
        />
      )}
      <MenuButton isOpen={isSidebarOpen}>
        <FlexRow
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap={"5px"}
          onClick={toggleSidebar}
        >
          <NavTitle isOpen={isSidebarOpen}>תפריט</NavTitle>
          <FiMenu size={isSidebarOpen ? 25 : 18} />
        </FlexRow>
        {isSidebarOpen && (
          <div>
            {NAVBAR_TABS.map((tab, index) => (
              <SideContent key={index} onClick={() => handleTabClick(tab)}>
                {tab.label}
                {tab.icon}
              </SideContent>
            ))}
          </div>
        )}
      </MenuButton>
    </>
  );
};

export default LandingSideBar;
