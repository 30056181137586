import { useContext, useEffect, lazy, Suspense } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { UserContext } from "../../../../store/userContext";
import { styled, createGlobalStyle } from "styled-components";
import BogartTrial500 from "../../../../assets/Fonts/bogart/Bogart-Medium-trial.ttf";
import BogartTrial600 from "../../../../assets/Fonts/bogart/Bogart-Semibold-trial.ttf";
import BogartTrial700 from "../../../../assets/Fonts/bogart/Bogart-Bold-trial.ttf";
import Bodoni72 from "../../../../assets/Fonts/bodoni72/1574087879852-Bodoni-72-Bold.otf";
import CooperLtBt from "../../../../assets/Fonts/cooper_lt_bt/CooperLtBTBold.ttf";
import NotoSans500 from "../../../../assets/Fonts/Noto_Sans_Hebrew/static/NotoSansHebrew-Medium.ttf";
import NotoSans600 from "../../../../assets/Fonts/Noto_Sans_Hebrew/static/NotoSansHebrew-SemiBold.ttf";
import NotoSans700 from "../../../../assets/Fonts/Noto_Sans_Hebrew/static/NotoSansHebrew-Bold.ttf";
import NotoSans800 from "../../../../assets/Fonts/Noto_Sans_Hebrew/static/NotoSansHebrew-ExtraBold.ttf";
import InknutAntiqua700 from "../../../../assets/Fonts/Inknut_Antiqua/InknutAntiqua-Bold.ttf";
import InknutAntiqua800 from "../../../../assets/Fonts/Inknut_Antiqua/InknutAntiqua-ExtraBold.ttf";

import Navbar from "../Navbar";
import SivanImage from "../../../../assets/images/Mock/User/SivanImage.png";
import TomImage from "../../../../assets/images/Mock/User/tom-cruise.png";
import LoadingSpinner from "../../Loading/Loading";
import ReturnArrow from "../ReturnArrow/ReturnArrow";
import { ROUTES } from "./constants";
import useGetCurrentUser from "./useGetCurrentUser";
import useRegion from "../../../../customHooks/useRegion";
import useCurrentRoute from "../../../../customHooks/useCurrentRoute";
import useMediaQueryConstants from "../../../../customHooks/useMediaQueryConstants";
import { getEnv } from "../../utils/env";
import { PopContext } from "../../../../store/popupContext";
import MapPopup from "../../../../kit/PopUpModal/MapPopup";
import { EmergencyOverlaysContext } from "../../../Emergency/Contacts/EmergencyOverlaysContext";
import EmergencyOverlaysHandler from "../../../Emergency/Contacts/EmergencyOverlaysHandler";

const PageNotFound = lazy(() => import("../../NotFound"));
const HomeRoutes = lazy(() => import("../../../Home/Route"));
const ActivitiesRoutes = lazy(() => import("../../../Ecosystem/Route"));
const AuthRoutes = lazy(() => import("../../../Auth/Route"));
const PublicActivities = lazy(() => import("../../../Ecosystem/Public/Routes"));
const MessagesRoutes = lazy(() => import("../../../Messages/Route"));
const NotificationsRoutes = lazy(() => import("../../../Notifications/Routes"));
const ProfileRoutes = lazy(() => import("../../../Profile/Routes"));
const RewardSystemRoutes = lazy(() => import("../../../RewardSystem/Route"));
const SearchRoutes = lazy(() => import("../../../Search/Route"));
const TasksRoutes = lazy(() => import("../../../Tasks/Route"));
const SettingsRoutes = lazy(() => import("../../../Settings/Route"));
const EmergencyRoutes = lazy(() => import("../../../Emergency/Routes"));
const InfoRoutes = lazy(() => import("../../../Info/Routes"));

const AdminRoutes = lazy(() => import("../../../Admin/Routes"));

const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'Bogart-trial-500';
    src: url(${BogartTrial500}) format('truetype');
  }
  @font-face {
    font-family: 'Bogart-trial-600';
    src: url(${BogartTrial600}) format('truetype');
  }
  @font-face {
    font-family: 'Bogart-trial-700';
    src: url(${BogartTrial700}) format('truetype');
  }
  @font-face {
    font-family: 'Bodoni72';
    src: url(${Bodoni72}) format('truetype');
  }
  @font-face {
    font-family: 'CooperLtBt-700';
    src: url(${CooperLtBt}) format('truetype');
  }
  @font-face {
    font-family: 'NotoSans-500';
    src: url(${NotoSans500}) format('truetype');
  }
  @font-face {
    font-family: 'NotoSans-600';
    src: url(${NotoSans600}) format('truetype');
  }
  @font-face {
    font-family: 'NotoSans-700';
    src: url(${NotoSans700}) format('truetype');
  }
  @font-face {
    font-family: 'NotoSans-800';
    src: url(${NotoSans800}) format('truetype');
  }
  @font-face {
    font-family: 'InknutAntiqua-700';
    src: url(${InknutAntiqua700}) format('truetype');
  }
  @font-face {
    font-family: 'InknutAntiqua-800';
    src: url(${InknutAntiqua800}) format('truetype');
  }
`;

const Wrapper = styled.div`
  position: relative;
  padding-bottom: ${(props) =>
    props.isAuthenticated && props.hasPaddingBottom ? "80px" : "0"};
`;

function App() {
  const {
    isAuthenticated,
    setIsAuthenticated,
    setCurrentUser,
    setInstallPrompt,
  } = useContext(UserContext);
  const { popupMap } = useContext(PopContext);
  const { overlayProps, showOverlay } = useContext(EmergencyOverlaysContext);

  const { setRegion } = useRegion();
  const { data } = useGetCurrentUser();
  const { isWideScreen, isMediumScreen } = useMediaQueryConstants();
  const currentRoute = useCurrentRoute();
  const navigate = useNavigate();

  const pagesDontHaveHorizontalNavbar = ["card-details"];
  const isHorizontalNavbar = !(
    currentRoute === "/" ||
    pagesDontHaveHorizontalNavbar.some((p) => currentRoute.includes(p))
  );
  useEffect(() => {
    if (data?.user) {
      const profileImage =
        data.user?.gender === "female" ? SivanImage : TomImage;
      setCurrentUser({
        ...data.user,
        image: profileImage,
      });
      setIsAuthenticated(true);
    }
  }, [data]);

  useEffect(() => {
    if (!showOverlay) {
      document.body.style.overflow = "visible";
    }
  }, [showOverlay]);

  useEffect(() => {
    setRegion(getEnv());
    const checkForBeforeInstallPrompt = () => {
      window.addEventListener("beforeinstallprompt", function (event) {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        event.preventDefault();
        // Stash the event so it can be triggered later.
        setInstallPrompt(event);
        clearInterval(intervalId);
      });
    };

    // Initial check
    checkForBeforeInstallPrompt();

    // Set up interval to check every 30 seconds
    const intervalId = setInterval(checkForBeforeInstallPrompt, 3000);
  }, []);

  return (
    <>
      <GlobalStyle />
      <ReturnArrow />
      <Wrapper
        isAuthenticated={isAuthenticated}
        hasPaddingBottom={!isWideScreen}
      >
        <>
          <Suspense
            fallback={<LoadingSpinner center={true} text={"Loading"} />}
          >
            <Routes>
              <Route
                path={`${ROUTES.EMERGENCY}/*`}
                element={<EmergencyRoutes />}
              />
              <Route
                path={`${ROUTES.PRIVATE_ZONE}/*`}
                element={<ActivitiesRoutes />}
              />
              <Route
                path={`${ROUTES.PUBLIC_ZONE}/*`}
                element={<PublicActivities />}
              />
              <Route path={`${ROUTES.SEARCH}/*`} element={<SearchRoutes />} />
              <Route path={`${ROUTES.INFO}/*`} element={<InfoRoutes />} />
              <Route path={`/*`} element={<AuthRoutes />} />
            </Routes>
          </Suspense>
        </>
        {isAuthenticated && (
          <>
            <Suspense
              fallback={<LoadingSpinner center={true} text={"Loading"} />}
            >
              <Routes>
                <Route path={`/*`} element={<HomeRoutes />} />
                <Route
                  path={`${ROUTES.PROFILE}/*`}
                  element={<ProfileRoutes />}
                />
                <Route
                  path={`${ROUTES.REWARD}/*`}
                  element={<RewardSystemRoutes />}
                />
                <Route
                  path={`${ROUTES.MESSAGES}/*`}
                  element={<MessagesRoutes />}
                />
                <Route
                  path={`${ROUTES.ACTIVITIES_AND_TASKS}/*`}
                  element={<TasksRoutes />}
                />
                <Route
                  path={`${ROUTES.NOTIFICATION}/*`}
                  element={<NotificationsRoutes />}
                />
                <Route
                  path={`${ROUTES.SETTINGS}/*`}
                  element={<SettingsRoutes />}
                />
                <Route path={`${ROUTES.ADMIN}/*`} element={<AdminRoutes />} />
              </Routes>
            </Suspense>
          </>
        )}
        {(isWideScreen || isMediumScreen) && isHorizontalNavbar ? (
          ""
        ) : (
          <Navbar />
        )}
        {popupMap && (
          <MapPopup
            id={popupMap}
            onClick={(id) => navigate(`/emergency/OverlayEmergencyMap/${id}`)}
          />
        )}
        {showOverlay && <EmergencyOverlaysHandler {...overlayProps} />}
      </Wrapper>
    </>
  );
}

export default App;
